(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/expandable-article/assets/javascripts/expandable-article.js') >= 0) return;  svs.modules.push('/widgets/expandable-article/assets/javascripts/expandable-article.js');

'use strict';

class ExpandableArticle {
  constructor(options) {
    var _this$el$;
    this.log = new svs.core.log.Logger('widgets:expandable-article');
    this.el = options.$el;
    this.collapsed = true;
    this.container = this.el[0];
    this.collapsedHeight = this.container.getBoundingClientRect().height;
    this.contentToggle = (_this$el$ = this.el[0]) === null || _this$el$ === void 0 ? void 0 : _this$el$.querySelector('.js-expandable-article-content-toggle');
    if (!this.contentToggle) {
      this.log.warn('Expandable article wont load, js-expandable-article-content-toggle was not found');
      return;
    }
    this.addEventListeners();
  }
  trackExpandClick() {
    const trackingName = this.container.dataset.track;
    if (trackingName) {
      svs.components.analytics.trackEvent({
        category: 'Articles',
        action: 'Read more',
        opt_label: trackingName
      });
    }
  }
  assembleTopHeaderHeight() {
    var _document$querySelect, _document$querySelect2, _document$querySelect3;
    const fixedTopContent = ((_document$querySelect = document.querySelector('.js-fixed-top-content')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.offsetHeight) || 0;
    const itemsMenuPoker = ((_document$querySelect2 = document.querySelector('.items-menu-wrapper-poker-react')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.offsetHeight) || 0;
    const menuCasino = ((_document$querySelect3 = document.querySelector('.js-casino-main-navigation')) === null || _document$querySelect3 === void 0 ? void 0 : _document$querySelect3.offsetHeight) || 0;
    const elementPosition = this.container.getBoundingClientRect().top + window.scrollY - this.collapsedHeight;
    const topHeaderHeight = this.container.offsetTop - (fixedTopContent + itemsMenuPoker + menuCasino);
    return topHeaderHeight > 0 ? topHeaderHeight : elementPosition;
  }
  toggleHandler() {
    if (this.collapsed) {
      this.contentToggle.setAttribute('aria-expanded', 'true');
      this.contentToggle.textContent = 'Läs mindre';
    } else {
      window.scrollTo({
        behavior: 'smooth',
        top: this.assembleTopHeaderHeight()
      });
      this.contentToggle.setAttribute('aria-expanded', 'false');
      this.contentToggle.textContent = 'Läs mer';
    }
    this.container.classList.toggle('expandable-article-collapsed');
    this.collapsed = !this.collapsed;
  }
  addEventListeners() {
    this.contentToggle.addEventListener('click', () => this.toggleHandler());
    this.contentToggle.addEventListener('click', () => this.trackExpandClick(), {
      once: true
    });
  }
  removeListeners() {
    this.contentToggle.removeListeners('click', () => this.toggleHandler(), this);
    this.contentToggle.removeEventListener('click', () => this.trackExpandClick(), {
      once: true
    });
  }
  destroy() {
    this.removeListeners();
  }
}
setGlobal('svs.widget.expandable_article.init', options => {
  if (options) {
    new ExpandableArticle(options);
  }
});

 })(window);